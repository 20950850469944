import * as React from 'react'
import { Link } from "gatsby"
import Layout from '/src/components/layout'
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLanguage, faPlug, faQuestionCircle, faMoneyBillAlt, faCarSide, faGear } from '@fortawesome/free-solid-svg-icons'

const laddskolaPage = () => {
    return (
        <Layout pageTitle="Laddskola">

            <p className="intro">
                Livet med laddbar bil kan innebära en del nya ord &amp; uttryck, prismodeller med mera. I Laddifys Laddskola hittar du det vanligaste du behöver veta som ny eller gammal elbilsförare. 
            </p>

            <div className="buttons">
                <Link to="/laddskola/ord-uttryck">
                    <FontAwesomeIcon icon={faLanguage} />
                    <p>Ord &amp; uttryck</p>
                </Link>
                <Link to="/laddskola/kontakter-farger">
                    <FontAwesomeIcon icon={faPlug} />
                    <p>Laddkontakter &amp; färger</p>
                </Link>
                <Link to="/laddskola/kostnader">
                    <FontAwesomeIcon icon={faMoneyBillAlt} />
                    <p>Kostnader</p>
                </Link>
                <Link to="/laddskola/skaffa-elbil">
                    <FontAwesomeIcon icon={faCarSide} />
                    <p>Skaffa elbil</p>
                </Link>
                <Link to="/laddskola/fragor-svar">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                    <p>Frågor &amp; svar</p>
                </Link>
                <Link to="/laddskola/verktyg">
                    <FontAwesomeIcon icon={faGear} />
                    <p>Verktyg</p>
                </Link>
                <Link to="/laddskola/laddify">
                    <StaticImage src="../../images/icon.png" alt="Laddify" height={45} />
                    <p>Använda Laddify</p>
                </Link>
            </div>

        </Layout>
    )
}

export default laddskolaPage